import { useEffect, useRef } from 'react';

export const usePrevious = (value: string[]) => {
    const ref = useRef<string[]>([]);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const usePreviousString = (value: string) => {
    const ref = useRef<string>('Domyślnie');
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

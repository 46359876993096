import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { H2 } from 'styles/Typography.styled';

export const FlatsWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 88px 136px;

    ${mediaQueries.md} {
        padding: 56px 16px;
    }
`;

export const TableSectionWrapper = styled.div`
    margin-bottom: 56px;
    width: 100%;

    ${mediaQueries.sm} {
        display: block;
        margin-bottom: 28px;
    }
`;

export const FiltersWrapper = styled.div`
    display: block;
    padding-bottom: 48px;

    ${mediaQueries.sm} {
        display: none;
    }
`;

export const TableWrapper = styled.div`
    display: block;

    ${mediaQueries.sm} {
        display: none;
    }
`;

export const SectionTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
    width: 100%;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const SectionTitle = styled(H2)``;

export const ClearFiltersButton = styled.button`
    margin-right: 30px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
`;

export const Text = styled.span(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            padding-left: 8px;
            color: ${colors.primaryBlue500};
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.bold};
            letter-spacing: ${letterSpacing.s};
            line-height: 24px;
        `,
);

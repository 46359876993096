import React from 'react';

export const Ogrodek = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="16" r="16" fill="#E3E5E8" />
            <path
                d="M19.3715 14.3117H12.8848C11.9671 14.3117 11.6482 13.6972 12.1848 12.9506L15.4282 8.40833C15.8093 7.86389 16.4471 7.86389 16.8282 8.40833L20.0715 12.9506C20.6082 13.6972 20.2893 14.3117 19.3715 14.3117Z"
                fill="#687280"
            />
            <path
                d="M20.4757 20.4871H11.7802C10.5513 20.4871 10.1313 19.6704 10.8546 18.6749L13.9579 14.3115H18.2979L21.4013 18.6749C22.1246 19.6704 21.7046 20.4871 20.4757 20.4871Z"
                fill="#687280"
            />
            <path
                d="M16.7116 20.4873V23.5984C16.7116 23.9173 16.4471 24.1818 16.1283 24.1818C15.8094 24.1818 15.5449 23.9173 15.5449 23.5984V20.4873H16.7116Z"
                fill="#687280"
            />
        </svg>
    );
};

import React from 'react';

export const Balcon = () => {
    return (
        <svg
            width="13"
            height="16"
            viewBox="0 0 13 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.56981 0V5.61344H12.1833C11.9546 2.62292 9.56033 0.228692 6.56981 0ZM6.56981 12.502V16H11.7318C11.991 16 12.2011 15.7899 12.2011 15.5307V13.1844H10.0762C8.87926 13.1844 7.68074 12.95 6.56981 12.502ZM6.56981 6.55198V11.4806C7.66541 11.9821 8.87144 12.2458 10.0762 12.2458H12.2011V6.55198H6.56981ZM7.97763 9.43019C7.71847 9.43019 7.50836 9.22008 7.50836 8.96091C7.50836 8.70175 7.71847 8.49164 7.97763 8.49164C8.23679 8.49164 8.4469 8.70175 8.4469 8.96091C8.4469 9.22008 8.23679 9.43019 7.97763 9.43019ZM9.85472 11.3073C9.59556 11.3073 9.38545 11.0972 9.38545 10.838C9.38545 10.5788 9.59556 10.3687 9.85472 10.3687C10.1139 10.3687 10.324 10.5788 10.324 10.838C10.324 11.0972 10.1139 11.3073 9.85472 11.3073ZM0 6.55198V10.3687H2.12487C3.32182 10.3687 4.52034 10.6031 5.63127 11.0511V6.55198H0ZM2.34636 9.43019C2.0872 9.43019 1.87709 9.22008 1.87709 8.96091C1.87709 8.70175 2.0872 8.49164 2.34636 8.49164C2.60553 8.49164 2.81563 8.70175 2.81563 8.96091C2.81563 9.22008 2.60553 9.43019 2.34636 9.43019ZM2.12487 11.3073H0V15.5307C0 15.7899 0.210109 16 0.469272 16H5.63127V12.0725C4.53567 11.571 3.32964 11.3073 2.12487 11.3073ZM3.28491 14.1229C3.02574 14.1229 2.81563 13.9128 2.81563 13.6536C2.81563 13.3945 3.02574 13.1844 3.28491 13.1844C3.54407 13.1844 3.75418 13.3945 3.75418 13.6536C3.75418 13.9128 3.54407 14.1229 3.28491 14.1229ZM0.0178323 5.61344H5.63127V0C2.64075 0.228692 0.246524 2.62292 0.0178323 5.61344Z"
                fill="#20263B"
            />
        </svg>
    );
};

export const Tree = () => {
    return (
        <svg
            width="12"
            height="17"
            viewBox="0 0 12 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.37151 6.31167H2.88484C1.96706 6.31167 1.64818 5.69722 2.18484 4.95056L5.42818 0.408333C5.80929 -0.136111 6.44707 -0.136111 6.82818 0.408333L10.0715 4.95056C10.6082 5.69722 10.2893 6.31167 9.37151 6.31167Z"
                fill="#20263B"
            />
            <path
                d="M10.4757 12.4871H1.78017C0.551278 12.4871 0.131278 11.6704 0.854611 10.6749L3.95794 6.31152H8.29794L11.4013 10.6749C12.1246 11.6704 11.7046 12.4871 10.4757 12.4871Z"
                fill="#20263B"
            />
            <path
                d="M6.71159 12.4873V15.5984C6.71159 15.9173 6.44714 16.1818 6.12826 16.1818C5.80937 16.1818 5.54492 15.9173 5.54492 15.5984V12.4873H6.71159Z"
                fill="#20263B"
            />
        </svg>
    );
};

export const ShowFiltersButton = () => {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="28" cy="28" r="28" fill="#466BA2" />
            <path
                d="M37.2995 23.5797H31.7195C31.3295 23.5797 31.0195 23.2697 31.0195 22.8797C31.0195 22.4897 31.3295 22.1797 31.7195 22.1797H37.2995C37.6895 22.1797 37.9995 22.4897 37.9995 22.8797C37.9995 23.2697 37.6895 23.5797 37.2995 23.5797Z"
                fill="white"
            />
            <path
                d="M22.42 23.5797H18.7C18.31 23.5797 18 23.2697 18 22.8797C18 22.4897 18.31 22.1797 18.7 22.1797H22.42C22.81 22.1797 23.12 22.4897 23.12 22.8797C23.12 23.2697 22.8 23.5797 22.42 23.5797Z"
                fill="white"
            />
            <path
                d="M26.1404 26.8297C28.322 26.8297 30.0904 25.0612 30.0904 22.8797C30.0904 20.6982 28.322 18.9297 26.1404 18.9297C23.9589 18.9297 22.1904 20.6982 22.1904 22.8797C22.1904 25.0612 23.9589 26.8297 26.1404 26.8297Z"
                fill="white"
            />
            <path
                d="M37.2999 33.8102H33.5799C33.1899 33.8102 32.8799 33.5002 32.8799 33.1102C32.8799 32.7202 33.1899 32.4102 33.5799 32.4102H37.2999C37.6899 32.4102 37.9999 32.7202 37.9999 33.1102C37.9999 33.5002 37.6899 33.8102 37.2999 33.8102Z"
                fill="white"
            />
            <path
                d="M24.28 33.8102H18.7C18.31 33.8102 18 33.5002 18 33.1102C18 32.7202 18.31 32.4102 18.7 32.4102H24.28C24.67 32.4102 24.98 32.7202 24.98 33.1102C24.98 33.5002 24.66 33.8102 24.28 33.8102Z"
                fill="white"
            />
            <path
                d="M29.8602 37.0699C32.0417 37.0699 33.8102 35.3014 33.8102 33.1199C33.8102 30.9384 32.0417 29.1699 29.8602 29.1699C27.6786 29.1699 25.9102 30.9384 25.9102 33.1199C25.9102 35.3014 27.6786 37.0699 29.8602 37.0699Z"
                fill="white"
            />
        </svg>
    );
};

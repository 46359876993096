import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
    width: 100%;
    position: relative;
`;

export const Text = styled.span(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) => css`
        position: absolute;
        bottom: 13px;
        left: 16px;
        color: ${colors.neutral300};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.medium};
        letter-spacing: ${letterSpacing.s};
    `,
);

export const Input = styled.input(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) => css`
        padding: 0px 28px 8px 42px;
        margin: 0;
        width: 100%;
        border: none;
        outline: none;
        border-bottom: 1px solid ${colors.neutral500};
        background: transparent;
        color: ${colors.neutral500};
        caret-color: ${colors.primaryBlue500};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.medium};
        letter-spacing: ${letterSpacing.s};
        line-height: 28px;
        border-radius: 0px;

        &::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &:active,
        &:focus {
            border-color: ${colors.primaryBlue500};
        }
    `,
);

import { Breadcrumbs } from 'components/_layout/Breadcrumbs/Breadcrumbs';
import styled, { css } from 'styled-components';
import { H1, H4, SectionText } from 'styles/Typography.styled';

export const MieszkaniaHeroWrapper = styled.div`
    position: relative;

    #img-mapper {
        z-index: 0;
    }
`;

export const HeaderHeroWrapper = styled.div`
    bottom: 106px;
    display: flex;
    flex-direction: column;
    left: 136px;
    position: absolute;
    z-index: 1;
`;

export const Tooltip = styled.span<{ isVisible: boolean }>(
    ({ theme: { colors }, isVisible }) => css`
        background: ${colors.white};
        color: ${colors.neutral900};
        min-width: 200px;
        padding: 24px;
        pointer-events: none;
        position: fixed;
        transform: translate3d(-50%, -50%, 0);
        visibility: ${isVisible ? 'visible' : 'hidden'};
        z-index: 1000;

        &:after {
            background: ${colors.white};
            bottom: -2px;
            content: '';
            height: 14px;
            left: 50%;
            position: fixed;
            transform: rotate(45deg) translate(-50%, 50%);
            width: 19px;
        }
    `,
);

export const HeroTitle = styled(H1)(
    ({ theme: { colors } }) => css`
        z-index: 1;
        color: ${colors.white};
    `,
);

export const BreadcrumbsStyled = styled(Breadcrumbs)`
    z-index: 1;
`;

export const StyledTooltipBuilding = styled(H4)`
    margin-bottom: 8px;
`;

export const StyledTooltipFloor = styled(SectionText)(
    ({ theme: { colors, fontWeight } }) => css`
        color: ${colors.neutral900};
        font-weight: ${fontWeight.medium};
        margin-bottom: 4px;
    `,
);

export const StyledTooltipFree = styled(SectionText)(
    ({ theme: { colors, fontWeight } }) => css`
        color: ${colors.neutral900};
        font-weight: ${fontWeight.medium};

        span {
            color: ${colors.green};
        }
    `,
);

export const InfoBox = styled.div(
    ({ theme: { colors } }) => css`
        background-color: rgba(255, 255, 255, 0.9);
        border: 1px solid ${colors.neutral100};
        height: fit-content;
        margin-top: 32px;
        padding: 32px;
        width: 294px;
        z-index: 1;
    `,
);

export const sharedStyles = css(
    ({ theme: { colors, fontWeight } }) =>
        css`
            color: ${colors.neutral900};
            display: flex;
            font-weight: ${fontWeight.medium};
            justify-content: space-between;

            span {
                font-weight: ${fontWeight.bold};
            }
        `,
);

export const StyledIsFreeText = styled(SectionText)(
    ({ theme: { colors } }) => css`
        ${sharedStyles}
        margin-bottom: 8px;

        span {
            color: ${colors.green};
        }
    `,
);

export const StyledReserveText = styled(SectionText)(
    ({ theme: { colors } }) => css`
        ${sharedStyles}
        margin-bottom: 8px;

        span {
            color: ${colors.yellow};
        }
    `,
);

export const StyledSoldText = styled(SectionText)(
    ({ theme: { colors } }) => css`
        ${sharedStyles}
        margin-bottom: 8px;

        span {
            color: ${colors.red};
        }
    `,
);

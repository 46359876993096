import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { HeroText } from 'styles/Typography.styled';

export const Container = styled.div(
    ({ theme: { colors } }) => css`
        display: grid;
        grid-gap: 24px;
        place-items: center;
        padding: 22px 130px 0px;
        position: relative;
        text-align: center;
        width: 100%;

        ${mediaQueries.md} {
            padding: 80px 36px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ${mediaQueries.sm} {
            background: ${colors.white};
            margin-bottom: 0;
            padding: 80px 40px;
        }

        ${mediaQueries.xs} {
            padding: 60px 16px;
        }
    `,
);

export const EmptyResultText = styled(HeroText)(
    ({ theme: { colors } }) => css`
        color: ${colors.neutral300};
        max-width: 630px;
    `,
);

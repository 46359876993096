import { Checkbox } from 'components/Checkbox/Checkbox';
import Select from 'react-select';
import styled, { css } from 'styled-components';

export const SelectWrapper = styled.div`
    text-align: start;
`;

export const OptionLabel = styled.label`
    cursor: pointer;
`;

export const Label = styled.label(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            color: ${colors.neutral900};
            font-size: ${fontSizes.f12};
            font-weight: ${fontWeight.semiBold};
            letter-spacing: ${letterSpacing.l};
            line-height: 16px;
            text-transform: uppercase;
        `,
);

export const StyledCheckbox = styled(Checkbox)`
    margin-right: 16px;
`;

export const StyledSelect = styled(Select)``;

export const SelectOption = styled.div`
    align-items: center;
    display: flex;
    gap: 16px;
`;

export const SelectSingleOption = styled.div`
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: space-between;
`;

export const customStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        background: state.isFocused ? '#DBE5F0' : 'white',
        color: '#3C465B',
        cursor: 'pointer',
        padding: '10px 16px',

        '&:last-child': {
            paddingBottom: '14px',
        },
    }),
    container: (provided: any) => ({
        ...provided,
        background: 'transparent',
        marginTop: '10px',
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        border: 'none',
        borderBottom: state.isFocused
            ? '1px solid #466BA2'
            : '1px solid #3C465B',
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: 'unset',
        '&:hover': {
            borderColor: '#466BA2',
        },
    }),
    clearIndicator: (provided: any) => ({
        ...provided,
        display: 'none',
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        display: 'none',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    multiValue: (provided: any) => ({
        ...provided,
        background: 'transparent',
    }),
    multiValueRemove: (provided: any) => ({
        ...provided,
        display: 'none',
    }),
    menu: (provided: any) => ({
        ...provided,
        //TODO: remove if not necessary
        zIndex: '9',
        margin: '0px',
        borderRadius: 'none',
        boxShadow: '0px 5px 20px rgba(70, 107, 162, 0.15)',
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: '#687280',
    }),
    valueContainer: (provided: any, state: any) => ({
        ...provided,
        paddingLeft: '16px',
        transform: state.isFocused
            ? 'translate(0, 0%) scale(1)'
            : 'transform: translate(0, -50%) scale(1)',
        fontWeight: '500',
        letterSpacing: '0.01em',
    }),
    menuList: (provided: any) => ({
        ...provided,
        margin: '0px',
        padding: '0px',
    }),
    singleValue: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
};

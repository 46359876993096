import * as React from 'react';
import { SVGProps } from 'react';

export const Featured = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
    >
        <circle cx={16} cy={16} r={16} fill="#E3E5E8" />
        <path
            fill="#687280"
            d="M9.333 13.5v-.833a4 4 0 0 1 4-4h5.334a4 4 0 0 1 4 4v.834c-1.473 0-2.167 1.027-2.167 2.5v2h-9v-2c0-1.473-.694-2.5-2.167-2.5Zm13.11.852c-.656.107-1.11.722-1.11 1.386v2.596a.667.667 0 0 1-.666.666h-9.334a.667.667 0 0 1-.666-.666v-2.595c0-.665-.454-1.28-1.11-1.387A1.334 1.334 0 0 0 8 15.667v2.667c0 1.09.524 2.058 1.333 2.666v1.334a.667.667 0 0 0 1.334 0V21.6c.215.044.438.066.666.066h9.334c.228 0 .451-.023.666-.067v.734a.667.667 0 0 0 1.334 0V21A3.328 3.328 0 0 0 24 18.334v-2.667c0-.81-.722-1.45-1.557-1.315Z"
        />
    </svg>
);

import React from 'react';

export const Balkon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="16" r="16" fill="#E3E5E8" />
            <g clipPath="url(#clip0_606_23063)">
                <path
                    d="M16.4692 8V13.6134H22.0827C21.854 10.6229 19.4597 8.22869 16.4692 8ZM16.4692 20.502V24H21.6312C21.8904 24 22.1005 23.7899 22.1005 23.5307V21.1844H19.9756C18.7787 21.1844 17.5802 20.95 16.4692 20.502ZM16.4692 14.552V19.4806C17.5648 19.9821 18.7709 20.2458 19.9756 20.2458H22.1005V14.552H16.4692ZM17.877 17.4302C17.6179 17.4302 17.4078 17.2201 17.4078 16.9609C17.4078 16.7017 17.6179 16.4916 17.877 16.4916C18.1362 16.4916 18.3463 16.7017 18.3463 16.9609C18.3463 17.2201 18.1362 17.4302 17.877 17.4302ZM19.7541 19.3073C19.495 19.3073 19.2849 19.0972 19.2849 18.838C19.2849 18.5788 19.495 18.3687 19.7541 18.3687C20.0133 18.3687 20.2234 18.5788 20.2234 18.838C20.2234 19.0972 20.0133 19.3073 19.7541 19.3073ZM9.89941 14.552V18.3687H12.0243C13.2212 18.3687 14.4198 18.6031 15.5307 19.0511V14.552H9.89941ZM12.2458 17.4302C11.9866 17.4302 11.7765 17.2201 11.7765 16.9609C11.7765 16.7017 11.9866 16.4916 12.2458 16.4916C12.5049 16.4916 12.715 16.7017 12.715 16.9609C12.715 17.2201 12.5049 17.4302 12.2458 17.4302ZM12.0243 19.3073H9.89941V23.5307C9.89941 23.7899 10.1095 24 10.3687 24H15.5307V20.0725C14.4351 19.571 13.2291 19.3073 12.0243 19.3073ZM13.1843 22.1229C12.9252 22.1229 12.715 21.9128 12.715 21.6536C12.715 21.3945 12.9252 21.1844 13.1843 21.1844C13.4435 21.1844 13.6536 21.3945 13.6536 21.6536C13.6536 21.9128 13.4435 22.1229 13.1843 22.1229ZM9.91725 13.6134H15.5307V8C12.5402 8.22869 10.1459 10.6229 9.91725 13.6134Z"
                    fill="#687280"
                />
            </g>
            <defs>
                <clipPath id="clip0_606_23063">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(8 8)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

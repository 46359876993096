import React from 'react';
import { FilePDF } from 'shared/svg/filepdf';
import styled, { css } from 'styled-components';

interface IconPDFProps {
    link: string;
    size?: number;
}

export const IconPDF = ({ link, size }: IconPDFProps) => {
    return (
        <Wrapper href={link} target={'_blank'}>
            <FilePDF size={size} />
        </Wrapper>
    );
};

const Wrapper = styled.a(
    ({ theme: { colors } }) =>
        css`
            cursor: pointer;
            display: grid;
            margin: 0 12px;
            place-items: center;
            z-index: 1;

            :hover {
                :before {
                    background-color: ${colors.primaryBlue200};
                    border-radius: 50%;
                    content: '';
                    height: 42px;
                    position: absolute;
                    width: 42px;
                    z-index: -1;
                }
            }
        `,
);

import { useQuery } from '@apollo/client';
import React, { useRef, useState } from 'react';
import ImageMapper, { AreaEvent, CustomArea } from 'react-img-mapper';
import { ALL_AVAILABLE_FLATS_QUERY } from 'src/apollo/queries';
import { MediaDetails } from 'types/CommonTypes';
import { useCurrentWidth } from 'utils/hooks/useCurrentWidth';
import { useImageRatio } from 'utils/hooks/useImageRatio';
import {
    BreadcrumbsStyled,
    HeaderHeroWrapper,
    HeroTitle,
    InfoBox,
    MieszkaniaHeroWrapper,
    StyledIsFreeText,
    StyledReserveText,
    StyledSoldText,
    StyledTooltipBuilding,
    StyledTooltipFloor,
    StyledTooltipFree,
    Tooltip,
} from './FlatsHeroDesktop.styled';

interface FlatsHeroDesktopProps {
    breadcrumbText: string;
    id: string;
    searchMap: any;
    searchMapImage: string;
    searchMapImageMediaDetails: MediaDetails;
}

interface StateTypes {
    tooltipIsVisible: boolean;
    building?: string | null;
    floor?: string | null;
    freeFlat?: string | null;
}

export const FlatsHeroDesktop = ({
    breadcrumbText,
    id,
    searchMap,
    searchMapImage,
    searchMapImageMediaDetails,
}: FlatsHeroDesktopProps) => {
    const [tooltip, setTooltip] = useState<StateTypes>({
        tooltipIsVisible: false,
        building: null,
        floor: null,
        freeFlat: null,
    });
    const currentWidth = useCurrentWidth();
    const currentRatio = useImageRatio(searchMapImageMediaDetails);
    const tooltipRef = useRef<HTMLSpanElement>(null);
    let cursorPositionX = 0;
    let cursorPositionY = 0;

    const moveOnArea = (area: CustomArea, index: number, event: AreaEvent) => {
        cursorPositionX = event.clientX;
        cursorPositionY = event.clientY;
        tooltipRef.current!.style.left = `${cursorPositionX}px`;
        tooltipRef.current!.style.top = `${cursorPositionY - 80}px`;
    };

    const enterArea = (area: any) => {
        setTooltip({
            tooltipIsVisible: true,
            building: area?.building,
            floor: area?.floor,
            freeFlat:
                area?.availableCount === null || undefined
                    ? '0'
                    : area?.availableCount,
        });
    };

    const leaveArea = () => {
        setTooltip({
            tooltipIsVisible: false,
            building: null,
            floor: null,
            freeFlat: null,
        });
    };

    const { data } = useQuery(ALL_AVAILABLE_FLATS_QUERY, {
        variables: {
            id: id,
        },
    });

    return (
        <MieszkaniaHeroWrapper>
            <HeaderHeroWrapper>
                <BreadcrumbsStyled isWhite text={breadcrumbText} />
                <HeroTitle>Wybierz piętro</HeroTitle>
                <InfoBox>
                    <StyledIsFreeText>
                        <span>Wolne</span>
                        {data?.availableFlats?.pageInfo?.total}
                    </StyledIsFreeText>
                    <StyledReserveText>
                        <span>Zarezerwowane</span>
                        {data?.bookedFlats?.pageInfo?.total}
                    </StyledReserveText>
                    <StyledSoldText>
                        <span>Sprzedane</span>
                        {data?.soldFlats?.pageInfo?.total}
                    </StyledSoldText>
                </InfoBox>
            </HeaderHeroWrapper>
            <ImageMapper
                imgWidth={searchMapImageMediaDetails?.width}
                onMouseEnter={(area: CustomArea) => {
                    enterArea(area);
                }}
                onMouseLeave={() => leaveArea()}
                onMouseMove={(
                    area: CustomArea,
                    index: number,
                    event: AreaEvent,
                ) => moveOnArea(area, index, event)}
                src={searchMapImage}
                fillColor={'rgb(70, 107, 162, 0.7)'}
                width={currentWidth}
                height={currentWidth * currentRatio}
                map={{ name: 'building', areas: searchMap }}
                key={'HeroMieszkania'}
            />
            <Tooltip ref={tooltipRef} isVisible={tooltip.tooltipIsVisible}>
                <StyledTooltipBuilding>
                    Budynek {tooltip.building}
                </StyledTooltipBuilding>
                <StyledTooltipFloor>Piętro: {tooltip.floor}</StyledTooltipFloor>
                <StyledTooltipFree>
                    <span>Wolne</span>: {tooltip.freeFlat}
                </StyledTooltipFree>
            </Tooltip>
        </MieszkaniaHeroWrapper>
    );
};

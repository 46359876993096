import React, { useState } from 'react';
import {
    components,
    MultiValueProps,
    OnChangeValue,
    OptionProps,
} from 'react-select';
import { Control, useController } from 'react-hook-form';
import {
    customStyles,
    Label,
    OptionLabel,
    SelectOption,
    SelectSingleOption,
    SelectWrapper,
    StyledCheckbox,
    StyledSelect,
} from './Select.styled';
import { CheckIcon } from 'shared/svg/check';

export type SelectType = {
    label: string;
    value: string;
};

interface SelectProps {
    closeMenuOnSelect?: boolean;
    control: Control;
    hideSelectedOptions?: boolean;
    isMulti?: boolean;
    label: string;
    name: string;
    options: SelectType[];
}

export const Select = ({
    closeMenuOnSelect = false,
    control,
    hideSelectedOptions = false,
    isMulti,
    label,
    name,
    options,
}: SelectProps) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
        defaultValue: isMulti ? options : options[0],
    });

    const [placeholder, setPlaceholder] = useState('Wszystkie');
    const [isAllOptionSelected, setIsAllOptionSelected] = useState(true);

    const handleChange = (selected: OnChangeValue<SelectType, true>) => {
        if (isMulti) {
            const isAll = selected.find((item) => item.value === 'all');
            if (isAll && !isAllOptionSelected) {
                setIsAllOptionSelected(true);
                return onChange(options);
            }
            if (isAll && isAllOptionSelected) {
                setIsAllOptionSelected(false);
                setPlaceholder('Wybierz...');
                return onChange([]);
            }
            setIsAllOptionSelected(false);
            onChange(selected);
        } else {
            return onChange([selected]);
        }
    };
    const allOption = {
        label: isAllOptionSelected ? 'Odznacz wszystkie' : 'Zaznacz wszystkie',
        value: 'all',
    };

    const MultiValue = (props: MultiValueProps<SelectType>) => {
        const { getValue } = props;
        const optionsCount = options.length;
        const selectedOptionsCount = getValue().length;
        if (optionsCount === selectedOptionsCount) {
            setIsAllOptionSelected(true);
            return props.index === 0 ? <span>Wszystkie</span> : null;
        }
        return (
            <components.MultiValue {...props}>
                <span>{props.data.label};</span>
            </components.MultiValue>
        );
    };

    const Option = (props: OptionProps<SelectType>) => {
        if (isMulti) {
            const selectedOptions = props.data;
            return props.options[0] === selectedOptions ? (
                <components.Option {...props}>
                    <OptionLabel
                        onClick={() =>
                            setIsAllOptionSelected(!isAllOptionSelected)
                        }
                    >
                        {props.label}
                    </OptionLabel>
                </components.Option>
            ) : (
                <components.Option {...props}>
                    <SelectOption>
                        <StyledCheckbox
                            name="option"
                            control={control}
                            isChecked={props.isSelected}
                            select
                        />
                        <OptionLabel>{props.label}</OptionLabel>
                    </SelectOption>
                </components.Option>
            );
        } else {
            return (
                <components.Option {...props}>
                    <SelectSingleOption>
                        <OptionLabel>{props.label}</OptionLabel>
                        {props.isSelected && <CheckIcon />}
                    </SelectSingleOption>
                </components.Option>
            );
        }
    };

    return (
        <SelectWrapper>
            <Label>{label}</Label>
            {/* @ts-ignore */}
            <StyledSelect
                placeholder={placeholder}
                styles={customStyles}
                options={isMulti ? [allOption, ...options] : options}
                isMulti={isMulti}
                closeMenuOnSelect={closeMenuOnSelect}
                hideSelectedOptions={hideSelectedOptions}
                components={{ Option, MultiValue }}
                onChange={handleChange}
                value={value}
                inputId="clickableInput"
            />
        </SelectWrapper>
    );
};

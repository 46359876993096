import React from 'react';
import { InputWrapper, Text, Input } from './InputNumber.styled';

interface InputNumberProps {
    value: string;
    text: 'od' | 'do';
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const InputNumber = ({
    text,
    value,
    onChange,
    onBlur,
}: InputNumberProps) => {
    return (
        <InputWrapper>
            <Input
                type="text"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
            <Text>{text}</Text>
        </InputWrapper>
    );
};

import React from 'react';

export const TableSortDownIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M4.18221 10.2038L6.0171 12.3039L7.13747 13.5927C7.61191 14.1358 8.38359 14.1358 8.85803 13.5927L11.819 10.2038C12.2077 9.75891 11.9276 9 11.3846 9H8.17781H4.61664C4.06789 9 3.79352 9.75891 4.18221 10.2038Z"
                fill="#20263B"
            />
        </svg>
    );
};

export const TableSortUpIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M11.8178 5.79621L9.9829 3.69611L8.86253 2.40726C8.38809 1.86425 7.61641 1.86425 7.14197 2.40726L4.181 5.79621C3.79231 6.24109 4.0724 7 4.61543 7H7.82219H11.3834C11.9321 7 12.2065 6.24109 11.8178 5.79621Z"
                fill="#20263B"
            />
        </svg>
    );
};

export const TableNotSortIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M11.8178 5.79621L9.9829 3.69611L8.86253 2.40726C8.38809 1.86425 7.61641 1.86425 7.14197 2.40726L4.181 5.79621C3.79231 6.24109 4.0724 7 4.61543 7H7.82219H11.3834C11.9321 7 12.2065 6.24109 11.8178 5.79621Z"
                fill="#20263B"
            />
            <path
                d="M4.18221 10.2038L6.0171 12.3039L7.13747 13.5927C7.61191 14.1358 8.38359 14.1358 8.85803 13.5927L11.819 10.2038C12.2077 9.75891 11.9276 9 11.3846 9H8.17781H4.61664C4.06789 9 3.79352 9.75891 4.18221 10.2038Z"
                fill="#20263B"
            />
        </svg>
    );
};

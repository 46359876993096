import * as React from 'react';
import { SVGProps } from 'react';

export const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={24}
        height={24}
        {...props}
    >
        <path
            fill="#34538A"
            d="M10.011 17c-.28 0-.546-.112-.742-.307l-3.964-3.948a1.05 1.05 0 0 1 0-1.48 1.06 1.06 0 0 1 1.484 0l3.222 3.21 7.2-7.172a1.06 1.06 0 0 1 1.484 0 1.05 1.05 0 0 1 0 1.48l-7.942 7.91a1.052 1.052 0 0 1-.742.307Z"
        />
    </svg>
);

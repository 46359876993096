import styled, { css } from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';

export const InputWrapper = styled.div`
    padding-top: 18px;
    display: grid;
    gap: 0 20px;
    grid-template-columns: 1fr 1fr;
`;

export const FiltersBox = styled.div(
    ({ theme: { colors } }) => css`
        width: 100%;
        padding: 64px 70px 60px 70px;
        background-color: ${colors.primaryBlue60};
        display: grid;
        gap: 40px 30px;
        grid-template-columns: 1fr 1fr 1fr;

        ${mediaQueries.lg} {
            grid-template-columns: 1fr 1fr;
        }

        ${mediaQueries.md} {
            padding: 20px;
        }
    `,
);

export const FilterBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
    margin-top: auto;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: 1fr;
    gap: 0 24px;
`;

export const Label = styled.label(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            color: ${colors.neutral900};
            font-size: ${fontSizes.f12};
            font-weight: ${fontWeight.semiBold};
            letter-spacing: ${letterSpacing.l};
            line-height: 16px;
            text-transform: uppercase;
        `,
);

export const Button = styled.button<{ isActive?: boolean }>(
    ({
        theme: { colors, fontSizes, fontWeight, letterSpacing },
        isActive,
    }) => css`
        padding: 0;
        border: none;
        background-color: transparent;
        color: ${isActive ? colors.neutral500 : colors.neutral300};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.medium};
        letter-spacing: ${letterSpacing.s};
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            color: ${colors.primaryBlue500};
            div {
                border-color: ${colors.primaryBlue200};
                background-color: ${colors.primaryBlue200};
            }
            path {
                fill: ${colors.primaryBlue500};
            }
        }
    `,
);

export const IconWrapper = styled.div<{ isActive?: boolean }>(
    ({ theme: { colors }, isActive }) => css`
        width: 32px;
        height: 32px;
        margin-right: 8px;
        border-radius: 50%;
        border: 1px solid
            ${isActive ? colors.primaryBlue500 : colors.neutral300};
        background-color: ${isActive ? colors.primaryBlue500 : colors.white};
        display: flex;
        justify-content: center;
        align-items: center;
        path {
            fill: ${isActive ? colors.white : colors.neutral300};
        }
    `,
);

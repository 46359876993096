import styled, { css } from 'styled-components';
import { H1, SecondaryButton } from 'styles/Typography.styled';

export const MieszkaniaHeroWrapper = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.primaryBlue60};
        padding: 56px 32px 32px;
        text-align: center;
    `,
);

export const HeroTitle = styled(H1)`
    margin-bottom: 24px;
`;

export const HeroButton = styled(SecondaryButton)`
    margin: 0 auto;
    padding: 16px;
`;

export const IconWrapper = styled.div`
    bottom: 16px;
    position: fixed;
    right: 16px;
    z-index: 9;
`;

export const Number = styled.div(
    ({ theme: { colors } }) => css`
        top: -5px;
        position: absolute;
        right: 0px;
        z-index: 9;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: ${colors.neutral900};
        color: ${colors.white};
        font-weight: 600;
        font-size: 10px;
        line-height: 13px;
        display: grid;
        place-items: center;
    `,
);

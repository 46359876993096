import styled, { css } from 'styled-components';
import { LoadMoreButton } from 'styles/Table.styled';

export const FlatsListMobileWrapper = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        background-color: ${colors.primaryBlue60};
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 14px 56px;
    `,
);

export const FlatCardWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            background-color: ${colors.white};
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;
            padding: 36px;
            width: 100%;
        `,
);

export const LoadMoreStyled = styled(LoadMoreButton)`
    margin: 0 36px;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
`;

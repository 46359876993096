import React, { useEffect, useRef, useState } from 'react';
import {
    Input,
    Slider,
    Track,
    Range,
    Label,
    SliderWrapper,
    Wrapper,
} from './RangeSlider.styled';
interface RangeSliderProps {
    min: number;
    max: number;
    minValue: number;
    maxValue: number;
    onChange: Function;
    label?: string;
}

export const RangeSlider = ({
    min,
    max,
    minValue,
    maxValue,
    onChange,
    label,
}: RangeSliderProps) => {
    const minValueRef = useRef<HTMLInputElement>(null);
    const maxValueRef = useRef<HTMLInputElement>(null);
    const rangeRef = useRef<HTMLDivElement>(null);
    const [innerMin, setInnerMin] = useState<number>(minValue);
    const [innerMax, setInnerMax] = useState<number>(maxValue);

    const getPercent = (value: number) => {
        return Math.round(((value - min) / (max - min)) * 100);
    };

    const onMinValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.min(parseInt(event.target.value), maxValue - 1);
        setInnerMin(value);
        onChange({ min: value, max: maxValue });
    };

    const onMaxValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.max(parseInt(event.target.value), minValue + 1);
        onChange({ min: minValue, max: value });
    };

    useEffect(() => {
        if (minValue >= maxValue) {
            setInnerMin(maxValue - 1);
        } else if (minValue < min) {
            setInnerMin(min);
        } else {
            setInnerMin(minValue);
        }
    }, [minValue]);

    useEffect(() => {
        if (maxValue <= minValue) {
            setInnerMax(minValue + 1);
        } else if (maxValue > max) {
            setInnerMax(max);
        } else {
            setInnerMax(maxValue);
        }
    }, [maxValue]);

    useEffect(() => {
        const minPercent = getPercent(innerMin);
        const maxPercent = getPercent(parseInt(maxValueRef.current!.value));
        rangeRef.current!.style.left = `${minPercent}%`;
        rangeRef.current!.style.width = `${maxPercent - minPercent}%`;
    }, [innerMin]);

    useEffect(() => {
        const maxPercent = getPercent(innerMax);
        const minPercent = getPercent(parseInt(minValueRef.current!.value));
        rangeRef.current!.style.width = `${maxPercent - minPercent}%`;
    }, [innerMax]);

    return (
        <Wrapper>
            {label && <Label>{label}</Label>}
            <SliderWrapper>
                <Input
                    type="range"
                    min={min}
                    max={max}
                    value={innerMin}
                    ref={minValueRef}
                    onChange={(event) => onMinValueChange(event)}
                    zIndex={minValue > max - 100 ? 5 : 3}
                />
                <Input
                    type="range"
                    min={min}
                    max={max}
                    value={innerMax}
                    ref={maxValueRef}
                    onChange={(event) => onMaxValueChange(event)}
                />
                <Slider>
                    <Track />
                    <Range ref={rangeRef} />
                </Slider>
            </SliderWrapper>
        </Wrapper>
    );
};

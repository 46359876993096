import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
`;
export const SliderWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
`;

export const Label = styled.label(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            color: ${colors.neutral900};
            font-size: ${fontSizes.f12};
            font-weight: ${fontWeight.semiBold};
            letter-spacing: ${letterSpacing.l};
            line-height: 16px;
            padding-bottom: 16px;
        `,
);

export const Input = styled.input<{ zIndex?: number }>(
    ({ theme: { colors }, zIndex }) => css`
        -webkit-appearance: none;
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 16px;
            height: 16px;
            border: none;
            border-radius: 50%;
            border: 2px solid ${colors.white};
            cursor: pointer;
            margin-top: 4px;
            pointer-events: all;
            position: relative;
            background-color: ${colors.primaryBlue500};
        }
        &::-moz-range-thumb {
            -webkit-appearance: none;
            width: 16px;
            height: 16px;
            border: none;
            border-radius: 50%;
            border: 2px solid ${colors.white};
            cursor: pointer;
            margin-top: 4px;
            pointer-events: all;
            position: relative;
            background-color: ${colors.primaryBlue500};
        }

        pointer-events: none;
        position: absolute;
        height: 0;
        width: inherit;
        outline: none;
        z-index: ${zIndex ? zIndex : 4};
    `,
);

export const Slider = styled.div`
    position: relative;
    width: inherit;
`;

export const Track = styled.div(
    ({ theme: { colors } }) => css`
        width: inherit;
        height: 4px;
        position: absolute;
        background-color: ${colors.primaryBlue200};
        z-index: 1;
    `,
);

export const Range = styled.div(
    ({ theme: { colors } }) => css`
        height: 4px;
        position: absolute;
        background-color: ${colors.primaryBlue500};
        z-index: 2;
    `,
);

import React, { useState } from 'react';
import { H4 } from 'styles/Typography.styled';
import { Container, EmptyResultText } from './EmptyState.styled';

interface EmptyStateProps {
    text: string;
}
export const EmptyState = ({ text }: EmptyStateProps) => {
    return (
        <Container>
            <H4>Brak mieszkań do wyświetlenia.</H4>
            <EmptyResultText>{text}</EmptyResultText>
        </Container>
    );
};
